/* override variables here */
$t-icon-font-path: '~@televic/styling/icons/fonts';

/* include all Televic styling */
@import '@televic/styling/main';
@import 'assets/scss/fonts/opensans';

/* add application styles here. Needs to be wrapped in #televicbody */
#televicbody {
  background-color: $white;
  @import 'assets/scss/main';

  .p-datatable {
    tr.nohover:hover {
      background: none;
      cursor: default;
    }
  }

  .icon {
    .t-icon-delete {
      color: $red-100;
    }
  }

  .text-strong {
    font-weight: bold;
  }

  .list-wrapper {
    height: 100%;
    width: 250px;
    min-width: 250px;
  }

  .p-listbox {
    height: 100%;
  }

  .border-box {
    box-sizing: border-box;
  }

  // HACK to hide upload button for upload component
  // to make upload only on submit button click
  p-fileUpload {
    &.p-fileupload-no-upload-icon {
      .p-fileupload-choose-selected {
        .pi-upload {
          display: none;
        }

        .p-button-text {
          padding-left: $content-padding;
        }
      }
    }
  }

  p-tree {
    .t-icon-folder-star-alt {
      color: $primary-60;
    }
  }

  p-listbox {
    .p-listbox-empty-message {
      padding: 5px;
      text-align: center;
    }
  }
}

.cdk-drag-preview {
  @include televic-shadow('overlay');
  background-color: $white;
  // for drag and drop inside popup dialogs
  z-index: 99999 !important;
}

.tr-dragable-row.cdk-drag-preview {
  display: table;

  td {
    border: none;
    text-align: left;
    padding: 0 $content-padding;
    vertical-align: middle;
  }

  .iconcolumn {
    width: 45px;
    padding: 0;
    text-align: center;
  }

  .fillspace {
    width: 100%;
  }

  .shrinkspace {
    width: 1px;
    white-space: nowrap;
  }
}

li.cdk-drag-preview {
  list-style: none;
  > a,
  > span,
  > div {
    @include horizontal-layout();
    align-items: center;
    flex-grow: 1;
    padding: 0 15px;
    min-height: $listrow-height;
    border: none;
    cursor: pointer;
    .p-menuitem-icon,
    .zmdi,
    .fa,
    .t-icon,
    .pi {
      font-size: $icon-size-medium;
      margin-right: 10px;
      width: 1em;
      text-align: center;
    }
    &.active {
      background-color: $primary-40;
    }
    &.p-disabled {
      color: $grey-20;
    }
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
  .cdk-drag:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

// fix picklist responsive
@media screen and (max-width: 960px) {
  .p-picklist {
    --inline-spacing: 3.25px;

    .p-picklist-buttons {
      .p-button {
        margin-top: 30px;
      }
    }
  }
}

.p-tooltip.p-component .p-tooltip-text {
  word-break: break-word;
}
