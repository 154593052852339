@import '@televic/styling/variables/main';
@import '@televic/styling/mixins/main';

@mixin tr-card-size($size, $space) {
  width: $size;
  height: $size;
  margin: $space;
}

@mixin tr-generic-card() {
  @include televic-shadow('highlighted');
  background: $white;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
}
