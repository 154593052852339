.tr-actions {
  .p-menubar {
    background: transparent;
    border: none;
    padding: 0;
  }

  .p-submenu-icon {
    display: none;
  }

  .p-menuitem-icon {
    font-size: 18px;
    color: #fff;
  }

  tr-header & {
    float: right;
  }
}
