@import 'components/form';
@import 'components/header-panel';
@import 'components/tab-panel';
@import 'components/primeng';
@import 'mixins/mixins';
@import '@televic/styling/variables/main';
@import '@televic/styling/mixins/main';

[hidden] {
  display: none !important; // TODO THS: check if this can be moved to televic styling
}

/* Chrome/Opera/Safari */
::-webkit-input-placeholder {
  text-transform: capitalize;
}

/* Firefox 19+ */
::-moz-placeholder {
  text-transform: capitalize;
}

/* IE 10+ */
:-ms-input-placeholder {
  text-transform: capitalize;
}

/* Firefox 18- */
:-moz-placeholder {
  text-transform: capitalize;
}

.tr-no-text-transform-placeholder {
  /* Chrome/Opera/Safari */
  &::-webkit-input-placeholder,
  ::-webkit-input-placeholder {
    text-transform: none;
  }

  /* Firefox 19+ */
  &::-moz-placeholder,
  ::-moz-placeholder {
    text-transform: none;
  }

  /* IE 10+ */
  &:-ms-input-placeholder,
  :-ms-input-placeholder {
    text-transform: none;
  }

  /* Firefox 18- */
  &:-moz-placeholder,
  :-moz-placeholder {
    text-transform: none;
  }
}

.tr-flex-1 {
  flex: 1;
}

.tr-flex-unset {
  flex-grow: unset !important;
}

.tr-margin-item-small > * {
  margin: 0 5px 5px 0;
}

.tr-margin0 {
  margin: 0 !important;
}

.tr-full-width {
  width: 100%;
}

.tr-full-height {
  height: 100%;
}

.tr-border {
  border: 1px solid $grey-20;
}

.no-background {
  background: none !important;
}

.tr-disable-last-border tbody tr:last-child {
  border: none;
}

.tr-update-job-wizard .p-datatable-scrollable-body {
  max-height: calc(100vh - 417px) !important;
}

.tr-button-link {
  padding: 0 !important;
  line-height: 18px !important;
  height: 18px !important;
  min-width: auto !important;
  border: none !important;
  border-radius: 0;
  border-bottom: 1px solid $grey-20 !important;

  &:hover {
    border-color: $primary-100 !important;
  }
}

.tr-cursor-pointer {
  cursor: pointer !important;
}

// TODO: Refactor to get rid of tr-top-page-controls
tr-tabs-nav .tr-top-page-controls,
.tr-external-top-controls {
  position: absolute;
  top: 75px;
  right: 20px;

  > button,
  a {
    margin-left: 10px;
  }
}

.tr-list-container {
  padding: $content-padding;
}

.tr-artifacts-list .p-dropdown {
  width: inherit !important;
}

.tr-artifacts-list .p-dropdown .p-dropdown-label {
  max-width: 120px !important;
}

.control-panel {
  justify-content: center;
  align-items: center;
  width: 150px;
  padding: 0 10px;

  button {
    margin-bottom: 0.25em;
  }
}

.tr-cards-container-wrapper {
  background-color: $grey-6;

  .tr-cards-container {
    display: flex;
    flex-wrap: wrap;
    margin: 50px auto 0;
    max-width: 800px;
  }

  tr-card {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.tr-sub-route-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.tr-align-center-bottom {
  position: absolute;
  right: 50%;
  bottom: 20px;
  transform: translateX(50%);
}

.p-dialog {
  .p-dialog-content {
    overflow-y: auto;
  }

  p-tabview {
    margin: -20px -20px 0 -20px;
    padding: 10px;
  }

  // TODO: Refactor to dumb/smart components and real footer instead of fake one
  .fakedialogfooter {
    text-align: right;
    margin: 20px -20px -20px -20px;
    padding: 10px $content-padding;
    border-top: 1px solid $grey-20;
    background-color: $white;

    button {
      margin: 0 0 0 10px; //buttons are right aligned
    }
  }
}

.p-datatable.small-paginator .p-paginator {
  background: transparent;
  padding: 3px;

  .p-paginator-pages {
    height: 20px;
  }

  .p-paginator-element {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px;

    .p-paginator-icon {
      line-height: 20px;
      font-size: 14px;
    }
  }
}

form.large {
  .p-multiselect {
    width: 300px;
  }

  .p-autocomplete {
    .p-autocomplete-multiple-container
      .p-autocomplete-input-token
      input[disabled] {
      display: block;
    }

    .p-button.p-button-icon-only[disabled]:not(.loading),
    .p-button.p-button-icon-only[disabled]:not(.loading):hover {
      border-color: transparent;
    }
  }
}

// fix autocompleate with multiline list items
.p-autocomplete-panel ul.p-autocomplete-items li {
  padding: 6px 0;
  &.p-highlight {
    background-color: $primary-40;

    &:hover {
      background-color: $primary-40;
    }
  }
  &.p-autocomplete-emptymessage,
  span {
    min-height: 24px;

    span {
      min-height: unset;
    }
  }

  &.p-autocomplete-emptymessage {
    padding: 6px 15px;
  }
}

.tr-mini-card {
  .tr-card {
    @include tr-card-size(125px, 0);
    padding: 0;
    border: none;

    i {
      font-size: 36px;
      margin-bottom: 0;
    }

    h4 {
      font-size: 10px;
      white-space: nowrap;
    }
  }
}

.tr-medium-card {
  margin: 0;

  .tr-card {
    @include tr-card-size(150px, 10px);
  }
}

.text-center {
  text-align: center;
}

.required-mark:after {
  content: '*';
  color: $red-100;
}

.tr-error-text {
  color: $red-100;
}

.leaflet-container {
  @include televic-bodytext();
}

.leaflet-trains-map-marker-popup {
  margin-bottom: 10px;

  .leaflet-popup-content-wrapper {
    border-radius: 5px;
  }

  .leaflet-popup-content {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 25px;
  }

  .leaflet-popup-close-button {
    box-sizing: unset;
    padding-right: 7px;
    @include t-icon();
    color: transparent;

    &:before {
      content: $t-icon-close;
      color: $grey-100;
    }
  }

  .leaflet-popup-tip-container {
    width: 10px;
    height: 10px;
    margin-left: -5px;
    .leaflet-popup-tip {
      width: 5px;
      height: 5px;
      margin-top: -3px;
    }
  }
}

.leaflet-fade-anim .leaflet-trains-map-marker-popup.leaflet-popup {
  transition: none;
}

.leaflet-geoman-map {
  .marker-icon,
  .marker-icon:focus {
    width: 10px !important;
    height: 10px !important;
    margin: -6px 0 0 -6px !important;
    border-color: $red-100;
  }

  // override for hintlineStyle
  // as for some reason it's not overridden from code
  // for Circles drown from code
  .leaflet-interactive {
    stroke: $red-100;
    stroke-width: 1;
  }
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.tr-tooltip-icon {
  vertical-align: middle;
  margin-left: 3px;
  color: $grey-60;

  &:hover {
    color: $grey-100;
  }
}

.color {
  &-grey {
    color: $grey-60;
  }

  &-yellow {
    color: $yellow-60;
  }

  &-green {
    color: $green-80;
  }

  &-red {
    color: $red-60;
  }

  &-orange {
    color: $orange-60;
  }

  &-blue {
    color: $blue-60;
  }

  &-disabled {
    color: $grey-20;
  }

  &-disabled-text {
    color: $grey-40;
  }
}

.textarea-with-table-donut-counter-wrapper {
  position: relative;
  .textarea-with-table-donut-counter {
    padding-right: 42px !important;
  }
  t-table-donut {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}

.tr-generic-card {
  @include tr-generic-card();
}

.select-button-stretch .p-button {
  flex-grow: 1;
}

.tr-dialog-maximized {
  transition: none;
  transform: none;
  width: 100vw !important;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100%;
  left: 0 !important;
  top: 0 !important;
}

// prime flex overrides
.p-vertical-nogutter > .p-col,
.p-vertical-nogutter > [class*='p-col-'] {
  padding-top: 0;
  padding-bottom: 0;
}

.tr-selector-tabs {
  height: 100%;
  margin: -20px -20px 0 -20px;

  .p-tabview-panels {
    height: calc(100% - #{$titlebar-height});
  }

  .p-tabview-panel {
    height: 100%;
  }
}

p-accordion.p-accordion-fullheight {
  .p-accordion-tab,
  .p-toggleable-content,
  .p-accordion-content {
    height: 100%;
  }
}

p-accordiontab.p-accordion-error {
  .p-accordion-header-text {
    color: $red-100;
  }
}

p-accordiontab:not(.p-accordion-error) .p-accordion-header-text {
  color: inherit;
}

.tr-fileUpload-with-drag-and-drop {
  display: block;

  p-progressbar .p-progressbar {
    display: none;
  }

  .p-fileupload-row > div {
    width: unset;
  }

  .p-fileupload {
    position: relative;
  }

  p-button {
    display: none;
  }

  .p-fileupload-buttonbar {
    border: none;
    display: flex;
    justify-content: center;
    padding: 0;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .p-fileupload-content {
    padding: 20px 20px 70px;
    border: 2px dashed $grey-20;

    &.p-fileupload-highlight {
      border-color: $grey-100;
    }
  }

  .tr-fileUpload-with-drag-and-drop-content-instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.tr-drag-handle {
  cursor: move;
}

.t-link.t-link-no-underline {
  border-bottom: none;
}

t-sidebar aside .content nav a {
  padding-right: 20px;
}

p-chips.tr-full-width .p-chips .p-inputtext {
  width: 100%;
}

p-inputNumber.tr-full-width {
  &,
  .p-inputnumber,
  .p-inputnumber-input {
    width: 100%;
  }
}

p-table {
  .tr-date-column {
    min-width: 210px;
  }
}
